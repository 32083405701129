import React from 'react';
import styled from 'styled-components';
import ClientsCarousel from './clients-carousel';

const AboutContainer = styled.div`
  padding: 40px 20px;
  text-align: center;
`;

const Heading = styled.h1`
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2.5em;
`;

const Paragraph = styled.p`
  font-family: Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  margin: 0 auto 40px;
`;

const Client = () => {
  return (
    <AboutContainer>
      <Heading>Previous Clients</Heading>
      <Paragraph>
        Michelle has had the privilege of working with exemplary names in the industry, elevating their products to new sales heights. Here are some of the brands that have trusted Michelle with their food styling and photography needs.
      </Paragraph>
      <ClientsCarousel />
    </AboutContainer>
  );
};

export default Client;
