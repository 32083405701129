// src/components/Layout.js
import React from 'react';
import styled from 'styled-components';
import { useTheme, useMediaQuery } from '@mui/material';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import Footer from './Footer';

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  padding-top: 20vh;

  @media (max-width: 768px) {
    padding: 10px;
    padding-top: 15vh;
  }

  @media (max-width: 480px) {
    padding: 5px;
    padding-top: 10vh;
  }
`;

function Layout({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <LayoutContainer>
      {isMobile ? <HeaderMobile /> : <Header />}
      <MainContent>{children}</MainContent>
      <Footer />
    </LayoutContainer>
  );
}

export default Layout;
