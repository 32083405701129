// src/pages/Home.js
import React from 'react';
import Client from '../components/about-page-components/clients';
import AboutMe from '../components/about-page-components/about-me';

const style ={
  marginLeft: '-40px', // Add negative margin to extend the image beyond the layout
  marginRight: '-40px', // Add negative margin to extend the image beyond the layout 
}

function About() {
  return (
    <div style={style}>
      <Client />
      <AboutMe />
    </div>
  );
}

export default About;