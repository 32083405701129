import React from 'react';
import profileImage from '../../assets/headshot.jpg'; // Adjust the path to where your image is located
import Button from '../buttons/button';
import { styled } from '@mui/system';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2rem',
  maxWidth: '90%',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
}));

const Bio = styled('div')(({ theme }) => ({
  flex: 1,
  paddingRight: '10rem', // Adjust spacing as needed
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start', // Align items to the start (left)
  [theme.breakpoints.down('md')]: {
    paddingRight: '0',
    alignItems: 'center',
  },
}));

const Image = styled('img')(({ theme }) => ({
  flex: 1,
  maxWidth: '25vw', // Adjust size as needed
  borderRadius: '100vh', // Optional: if you want rounded corners
  [theme.breakpoints.down('md')]: {
    maxWidth: '50vw',
    marginTop: '2rem',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '70vw',
    marginTop: '2rem',
  },
}));

const Paragraph = styled('p')(({ theme }) => ({
  fontFamily: "'Helvetica Neue', Arial, sans-serif",
  fontSize: '1.2rem',
  lineHeight: '1.8rem',
  marginBottom: '1rem',
}));

const Heading = styled('h2')(({ theme }) => ({
  fontFamily: "'Helvetica Neue', Arial, sans-serif",
  fontWeight: 'bold',
  fontSize: '3rem',
  marginBottom: '1rem',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // Center the button horizontally
  width: '100%', // Make the buttonContainer take up full width
  marginTop: '2rem', // Add some spacing above the button
}));

const ThisIsMe = () => {
  return (
    <Container>
      <Bio>
        <Heading>Michelle Hunt</Heading>
        <Paragraph>
          Based in Dublin, Ireland, Michelle seamlessly merges her lifelong passions for food and photography into a captivating freelance career.
        </Paragraph>
        <Paragraph>
          As a food & drink photographer, videographer, and stylist, she excels in painting stories through her lens, enticing curiosity and tantalizing taste buds with images that not only showcase the natural allure of food but also inspire recreating these culinary experiences at home.
        </Paragraph>
        <Paragraph>
          Her services extend to <span style={{ fontWeight: 'bold' }}>stop motion and video production, alongside expert food, drink, and product styling, and recipe content creation</span>. Michelle elevates the presentation of food products, packaging, beauty, and lifestyle items, ensuring her work with clients across various industries shines with high-quality, consistent imagery for both web and print media.
        </Paragraph>
        <Paragraph>
          Michelle is more than a photographer; she's a storyteller, a stylist, and a coach in health and nutrition, making every shot a testament to her multifaceted expertise and passion.
        </Paragraph>
        <ButtonContainer>
          <Button text="View Portfolio" color="white" url="/portfolio" />
        </ButtonContainer>
      </Bio>
      <Image src={profileImage} alt="Profile" />
    </Container>
  );
};

export default ThisIsMe;
