import React from 'react';
import styled from 'styled-components';
import Button from '../buttons/button';
const AboutContainer = styled.div`
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
`;

const Heading = styled.h1`
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #333;
`;

const SubHeading = styled.h2`
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 2em;
  color: #444;
`;

const Paragraph = styled.p`
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 1.5;
  color: #555;
  max-width: 1000px;
  margin: 0 auto 40px;
  text-align: left;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  margin: 40px auto;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  padding: 30px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const FullWidthGridItem = styled(GridItem)`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AboutMe = () => {
  return (
    <AboutContainer>
      <Heading>About Michelle Hunt</Heading>
      <Section>
        <Paragraph>
          Michelle Hunt is a renowned food stylist and photographer with over 8 years of experience in the industry. Her passion for food and dedication to her craft have made her a trusted name among global culinary businesses. Michelle's expertise spans product photography, nutritional recipe creation, stop motion, video production, and professional photography.
        </Paragraph>
      </Section>
      <GridContainer>
        <GridItem>
          <SubHeading>Services</SubHeading>
          <Paragraph>
            <strong>Food & Product Photography:</strong> Michelle specializes in high-quality food & product photography that highlights the unique features and appeal of each culinary item. Michelle’s food photography services focus on styling and composition, creating visually appealing images that tell a story. Her services help brands enhance their visual presence and drive customer engagement.
          </Paragraph>
          <Paragraph>
            <strong>Stop Motion and Video Production:</strong> Michelle creates engaging stop motion and video content for social media campaigns, advertisements, and websites. Her professionally produced videos help brands enhance their digital marketing efforts and drive more traffic to their online platforms.
          </Paragraph>
          <Paragraph>
            <strong>Recipe Creation:</strong> Michelle develops nutritious recipes catering to various dietary needs. These recipes are visually appealing and healthy, perfect for marketing on social media, cookbooks, and promotional materials, connecting with health-conscious consumers.
          </Paragraph>
        </GridItem>

        <GridItem>
          <SubHeading>Achievements and Recognition</SubHeading>
          <Paragraph>
            <strong>Global Clientele:</strong> Michelle has worked with some of the biggest names in the culinary industry, including brands like Lidl, Kinetica, Lindt, Nourish, Linwoods, Lakeshore, Flahavan's, Arctic Power, Boyne Valley, Nutshed, Herology, Mileeven, Strong Roots, Homespun, NKD Living, Mother Root, Sacla, Sweetpea Pantry, Edible Health, Home Cook, Blanco Niño, Green Beards, and Chivers. Her work has helped these brands increase their market presence and sales.
          </Paragraph>
          <Paragraph>
            <strong>Industry Influence:</strong> As a thought leader in food styling and photography, Michelle regularly shares her insights and knowledge through workshops, seminars, and online content, inspiring others in the industry.
          </Paragraph>
        </GridItem>
        <FullWidthGridItem>
          <SubHeading>Contact Michelle</SubHeading>
          <Paragraph>
            For brands looking to elevate their culinary visuals, Michelle Hunt offers a range of services that cater to different needs and budgets. Contact Michelle today to discuss how she can help your brand stand out in the competitive market.
          </Paragraph>
          <Button text="Contact Me" color="white" url="/contact" />
        </FullWidthGridItem>
      </GridContainer>
    </AboutContainer>
  );
};

export default AboutMe;
