import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

const PortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const GalleryContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1.5;
  cursor: pointer;
`;

const IntroText = styled.div`
  margin-bottom: 20px;
  font-family: Helvetica, sans-serif;
  text-align: center;
  max-width: 1000px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
`;

const Heading = styled.h1`
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2.5em;
  color: #333;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
`;
// Function to dynamically load images from public/img
const importAll = (requireContext) => requireContext.keys().map(requireContext);

const images = importAll(require.context('/public/img', false, /\.(png|jpe?g|svg)$/)).map((filename) => ({
  src: filename.replace('/public', ''),
  thumbnail: filename.replace('/public', ''),
  width: 800,
  height: 1200,
}));

const Portfolio = () => {
  return (
    <PortfolioContainer>
      <IntroText>
        <Heading>Welcome to Michelle's Food Photography Portfolio</Heading>
        <Paragraph>
          Discover the art of food photography and styling with Michelle. Our services include professional food styling, product photography, recipe creation, nutrition consulting, and comprehensive consultancy services. Elevate your culinary brand with visually stunning imagery that captures the essence of your dishes. Whether you're a restaurant, a food brand, or a culinary enthusiast, Michelle provides top-tier services to enhance your visual storytelling. Contact us today to transform your food into captivating visuals and boost your online presence with our expert SEO marketing strategies.
        </Paragraph>
      </IntroText>
      <Gallery>
        <GalleryContainer>
          {images.map((image, index) => (
            <Item
              key={index}
              original={image.src}
              thumbnail={image.thumbnail}
              width={image.width}
              height={image.height}
            >
              {({ ref, open }) => (
                <LazyLoad height={200} offset={100}>
                  <Thumbnail
                    ref={ref}
                    onClick={open}
                    src={image.thumbnail}
                    alt={`Image ${index + 1}`}
                    loading="lazy"
                    srcSet={`
                      ${image.thumbnail} 300w,
                      ${image.src} 800w
                    `}
                    sizes="(max-width: 768px) 50vw, 25vw"
                  />
                </LazyLoad>
              )}
            </Item>
          ))}
        </GalleryContainer>
      </Gallery>
    </PortfolioContainer>
  );
};

export default Portfolio;
