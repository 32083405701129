import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/Logo.webp';

function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerStyle = {
    padding: scrolled ? '10px' : '20px', // Adjust padding as needed
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: scrolled ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 1)',
    boxShadow: scrolled ? '0 2px 5px rgba(0,0,0,0.1)' : 'none',
    transition: 'padding 0.3s, box-shadow 0.3s, background-color 0.3s',
    zIndex: 1000,
  };

  const logoStyle = {
    width: scrolled ? '5rem' : '7rem',
    height: 'auto',
    transition: 'width 0.3s',
  };

  const activeLinkStyle = {
    color: '#f2b0a0',
    textDecoration: 'underline',
  };

  const linkStyle = {
    fontFamily: 'Futura, sans-serif', // Use the imported font
    fontWeight: 'bold',
    fontSize: scrolled ? '16px' : '18px', // Adjust font size as needed
    margin: '0 15px',
    textDecoration: 'none',
    color: 'black', // Adjust link color as needed
    transition: 'font-size 0.3s',

    '&:hover': {
      textDecoration: 'underline',
    },
  };

  const navStyle = {
    marginRight: '3rem  '
  };

  return (
    <header style={headerStyle}>
      <div>
        <NavLink to="/" style={linkStyle} activeClassName={activeLinkStyle}>
          <img src={logo} alt="logo" style={logoStyle}/>
        </NavLink>
      </div>
      <nav style={navStyle}>
        <NavLink to="/" style={linkStyle} activeClassName={activeLinkStyle}>Home</NavLink>
        <NavLink to="/portfolio" style={linkStyle} activeClassName={activeLinkStyle}>Portfolio</NavLink>
        <NavLink to="/about" style={linkStyle} activeClassName={activeLinkStyle}>About</NavLink>
        <NavLink to="/contact" style={linkStyle} activeClassName={activeLinkStyle}>Contact</NavLink>
      </nav>
    </header>
  );
}

export default Header;
