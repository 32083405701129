// src/pages/Home.js
import React from 'react';
import Hero from '../components/home-page-components/hero';
import ThisIsMe from '../components/home-page-components/this-is-me';
import FeaturedWork from '../components/home-page-components/featured-work';
function Home() {
  return (
    <>
    <Hero />
    <ThisIsMe />
    <FeaturedWork />
    </>
    )
}

export default Home;