import React, { useState } from 'react';
import { 
  Container,
  Grid,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import emailjs from 'emailjs-com'; // Import emailjs

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // EmailJS service configuration
    const serviceID = 'YOUR_EMAILJS_SERVICE_ID';
    const templateID = 'YOUR_EMAILJS_TEMPLATE_ID';
    const userID = 'YOUR_EMAILJS_USER_ID';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Your message has been sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      })
      .catch((error) => {
        console.log('FAILED...', error);
        alert('Failed to send your message. Please try again later.');
      });
  };

  const styles = {
    contactSection: {
      width: '100vw',
      padding: '40px 20px',
      backgroundColor: '#fff',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textAlignLeft: {
      textAlign: 'left',
    },
  };

  return (
    <div style={styles.contactSection}>
      <div style={styles.formContainer}>
        <Container maxWidth="md">
          <Grid container spacing={10} alignItems="center">
            <Grid item xs={12} md={6} sx={styles.textAlignLeft}>
              <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Elevate Your Culinary Brand
              </Typography>
              <Typography variant="body1" sx={{ lineHeight: 1.8 }}> 
                Capture the essence of your culinary creations with professional food photography and styling. Our expertise in food styling, recipe development, and visual storytelling will help your brand stand out. Contact us for a customized quote and let's transform your dishes into captivating visuals.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Name"
                  fullWidth
                  margin="normal"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  fullWidth
                  margin="normal"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <TextField
                  label="Message"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                <Button variant="contained" color="secondary" type="submit" sx={{ bgcolor: 'black', color: 'white' }}>
                  Send Inquiry
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default ContactForm;
