// src/components/HeaderMobile.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItemButton, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/Logo.webp';

function HeaderMobile() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '10px 20px',
    zIndex: 1000,
  };

  const logoStyle = {
    width: '5rem',
    height: 'auto',
  };

  const drawerStyle = {
    width: '250px',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'black',
    fontFamily: 'Futura, sans-serif',
    fontWeight: 'bold',
  };

  const iconButtonStyle = {
    backgroundColor: 'white',
    color: 'grey', // Optional: Ensures the menu icon remains visible
    padding: '10px',
    borderRadius: '50%',
    marginRight: '50px',
  };

  return (
    <AppBar position="fixed" style={{ background: 'transparent', boxShadow: 'none' }}>
      <Toolbar style={headerStyle}>
        <NavLink to="/">
          <img src={logo} alt="logo" style={logoStyle} />
        </NavLink>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle} style={iconButtonStyle}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
          <Box
            sx={{
              width: 250,
              height: '100%',
              backgroundColor: 'white',
            }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            <List style={drawerStyle}>
              <ListItemButton component={NavLink} to="/" style={linkStyle}>
                <ListItemText primary="Home" />
              </ListItemButton>
              <ListItemButton component={NavLink} to="/portfolio" style={linkStyle}>
                <ListItemText primary="Portfolio" />
              </ListItemButton>
              <ListItemButton component={NavLink} to="/about" style={linkStyle}>
                <ListItemText primary="About" />
              </ListItemButton>
              <ListItemButton component={NavLink} to="/contact" style={linkStyle}>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderMobile;
