import React from 'react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import 'photoswipe/dist/photoswipe.css';
import styled from 'styled-components';
import image1 from '../../assets/images/image164.jpg';  // Adjust the path accordingly
import image2 from '../../assets/images/image158.jpg';  // Adjust the path accordingly
import image3 from '../../assets/images/image156.jpg';  // Adjust the path accordingly
import image4 from '../../assets/images/image144.jpg';  // Adjust the path accordingly

const GalleryContainer = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1 / 1.5;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }
`;

const SliderComponent = () => {
  const images = [image1, image2, image3, image4];

  return (
    <div>
      <Gallery>
        <GalleryContainer>
          {images.map((image, index) => (
            <Item
              key={index}
              original={image}
              thumbnail={image}
              width={600}
              height={750}
              caption="Food Photography"
            >
              {({ ref, open }) => (
                <Thumbnail
                  ref={ref}
                  onClick={open}
                  src={image}
                  alt={`Food Photography ${index + 1}`}
                />
              )}
            </Item>
          ))}
        </GalleryContainer>
      </Gallery>
    </div>
  );
};

export default SliderComponent;
