import React from 'react';
import { styled } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';

const IconWrapper = styled('div')`
  text-align: center;
  margin-top: 20px;

  & > * {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 3rem; /* Default font size for large screens */

    @media (max-width: 768px) {
      font-size: 3.5rem; /* Adjust font size for tablets and smaller devices */
    }

    @media (max-width: 480px) {
      font-size: 3.2rem; /* Adjust font size for mobile devices */
    }
  }
`;

const SocialIcons = () => {
  return (
    <IconWrapper>
      <InstagramIcon sx={{ color: 'white' }} />
    </IconWrapper>
  );
};

export default SocialIcons;
