import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const ClientLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; // Adjust height as needed
  margin-bottom: 20px;
`;

const ClientLogo = styled.img`
  max-width: 100%;
  max-height: 150px; // Adjust max-height as needed
  margin: 10px;
`;
const ClientsCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clientLogos = [
    '/clients/Lakeshore.png',
    '/clients/Nourish.png',
    '/clients/Lidl.png',
    '/clients/Kinetica.png',
    '/clients/Lindt.png',
    '/clients/Flahavans.png',
    '/clients/BoyneValley.png',
    '/clients/NutShed.png',
    '/clients/Linwoods.png',
    '/clients/Herology.png',
    '/clients/BlancoNino.png',
    '/clients/Chivers.png',
  ];


  return (
    <Slider {...settings}>
      {clientLogos.map((logo, index) => (
        <ClientLogoContainer key={index}>
          <ClientLogo src={logo} alt={`Client ${index + 1}`} />
        </ClientLogoContainer>
      ))}
    </Slider>
  );
};

export default ClientsCarousel;