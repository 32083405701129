import React from 'react';
import SliderComponent from './slider-component';
import { styled } from '@mui/system';

const Section = styled('section')(({ theme }) => ({
  padding: '4rem 2rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F8F9FA',
  marginLeft: '-30px', // Add negative margin to extend the image beyond the layout
  marginRight: '-30px', // Add negative margin to extend the image beyond the layout

  [theme.breakpoints.down('md')]: {
    padding: '3rem 1.5rem',
    marginLeft: '-20px',
    marginRight: '-20px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '2rem 1rem',
    marginLeft: '-10px',
    marginRight: '-10px',
  },
}));

const Heading = styled('h2')(({ theme }) => ({
  fontFamily: "'Helvetica Neue', Arial, sans-serif",
  fontWeight: 'bold',
  fontSize: '3rem',
  textAlign: 'center',
  marginBottom: '2rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const Description = styled('p')(({ theme }) => ({
  fontFamily: "'Helvetica Neue', Arial, sans-serif",
  fontSize: '1.2rem',
  textAlign: 'center',
  maxWidth: '700px',
  marginBottom: '3rem',
  lineHeight: '1.8rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '1.1rem',
    marginBottom: '2rem',
    lineHeight: '1.6rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '1.5rem',
    lineHeight: '1.4rem',
  },
}));

const SliderContainer = styled('div')(({ theme }) => ({
  maxWidth: '800px',
  width: '100%',
}));

const FeaturedWork = () => {
  return (
    <Section>
      <Heading>Featured Work</Heading>
      <Description>
        Explore a curated selection of Michelle's captivating food photography and styling projects, showcasing her exceptional talent and artistic vision.
      </Description>
      <SliderContainer>
        <SliderComponent />
      </SliderContainer>
    </Section>
  );
};

export default FeaturedWork;
