import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: white;
  color: black;
  padding: 20px;
  text-align: center;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
`;

const FooterLinks = styled.div`
  margin-bottom: 20px;
`;

const FooterLink = styled.a`
  color: #black;
  margin: 0 15px;
  text-decoration: none;
  font-size: 1em;

  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  font-size: 0.9em;
  margin-top: 20px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterLinks>
        <FooterLink href="/">Home</FooterLink>
        <FooterLink href="/portfolio">Portfolio</FooterLink>
        <FooterLink href="/about">About</FooterLink>
        <FooterLink href="/contact">Contact</FooterLink>
      </FooterLinks>
      <Copyright>
        &copy; {new Date().getFullYear()} Michelle Hunt. All rights reserved. All photos and media on this site are owned by Michelle Hunt and are protected by copyright laws. Unauthorized use is prohibited.
      </Copyright>
    </FooterContainer>
  );
}

export default Footer;
