import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Button = ({ text, color, url }) => {
  const [hover, setHover] = useState(false);

  const baseStyle = {
    margin: '2rem',
    padding: '1rem 2rem',
    fontSize: '1.2rem',
    borderRadius: '0.5rem',
    border: '2px solid',
    fontWeight: 500,
    lineHeight: 1,
    cursor: 'pointer',
    display: 'inline-block',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    userSelect: 'none',
    verticalAlign: 'middle',
    fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif',
  };

  const darkStyle = {
    ...baseStyle,
    color: 'white',
    backgroundColor: 'black',
    borderColor: 'black',
  };

  const whiteStyle = {
    ...baseStyle,
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',
  };

  const hoverStyle = {
    dark: {
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'white',
    },
    white: {
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'black',
    },
  };

  const currentStyle = color === 'dark' ? darkStyle : whiteStyle;
  const currentHoverStyle = color === 'dark' ? hoverStyle.dark : hoverStyle.white;

  return (
    <a
      href={url}
      style={hover ? { ...currentStyle, ...currentHoverStyle } : currentStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {text}
    </a>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['dark', 'white']).isRequired,
  url: PropTypes.string.isRequired,
};

export default Button;
