import React from 'react';
import styled from 'styled-components';
import headerImg from '../../assets/headerimg.jpg';
import SocialIcons from '../social-media-components/socials';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const HeroSection = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url(${headerImg});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 2rem;
  margin: -20px;



  @media (max-width: 768px) {
    height: 80vh;
    padding: 1rem;
  }
`;

const Bio = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  color: #fff;
  z-index: 2;

  @media (max-width: 768px) {
  }
`;

const Parallax = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${headerImg});
  background-attachment: fixed;

  @media (max-width: 768px) {
    background-attachment: scroll;
  }
`;

const HeaderTitle = styled.h1`
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1rem;
  padding: 2rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ParagraphStyle = styled.p`
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 2rem;
  padding: 2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const Button = styled.button`
  margin: 2rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  border-color: white;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  font-family: 'Roboto', 'Helvetica Neue', Arial, sans-serif;

  &:hover {
    color: #0A0B0D;
    background-color: #fff;
    border-color: #fff;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  @media (max-width: 768px) {
    margin: 1rem;
    padding: 0.8rem 1.6rem;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    margin: 0.5rem;
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
`;

const Hero = () => {
  useEffect(() => {
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.overflowX = 'hidden';
    document.documentElement.style.margin = '0';
    document.documentElement.style.padding = '0';
  }, []);

  return (
    <HeroSection>
      <Parallax />
      <Bio>
        <HeaderTitle>Food Photography & Styling</HeaderTitle>
        <ParagraphStyle>Showcasing Products at Their Finest with Passion and Precision</ParagraphStyle>
        <SocialIcons />
        <Link to="/about">
          <Button>About Me</Button>
        </Link>
      </Bio>
    </HeroSection>
  );
};

export default Hero;
